<template>
  <div :class="{ menu_fixed: scrolled }">
    <div>
      <h3>QUALITA' DELL'ARIA</h3>
      <v-list class="menu-dx">
        <v-list-item>
          <router-link :to="{ name: 'inquinanti' }"> Inquinanti </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'salute' }">
            Effetti sulla salute e sull'ambiente
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'fonti' }"> Fonti emissive </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'dati-qualita-aria' }">
            Accesso ai dati misurati
          </router-link>
        </v-list-item>
      </v-list>
    </div>

    <div v-if="page == 'inquinanti'">
      <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
      <v-list class="pt-0">
        <v-list-item>
          <!--a href="/qualita-aria/salute">
            Effetti sulla salute e sull'ambiente
          </a-->
          <router-link :to="{ name: 'salute' }">
            Effetti sulla salute e sull'ambiente
          </router-link>
        </v-list-item>
      </v-list>
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="http://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/aria/cartella-qualita-inquinanti"
            target="_blank"
          >
            ARPA inquinanti <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="http://www.arpa.piemonte.it/approfondimenti/glossario/?b_start:int=30"
            target="_blank"
          >
            ARPA glossario <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="https://www.iss.it/" target="_blank">
            Istituto Superiore di Sanità
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'effetti-salute'">
      <h3>APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="http://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/aria/cartella-qualita-inquinanti"
            target="_blank"
          >
            ARPA inquinanti <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="http://www.mapec-life.eu/" target="_blank">
            mapec-life <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="http://www.comune.torino.it/ambiente/bm~doc/inquinamento-dellaria-e-salute-umana.pdf"
            target="_blank"
          >
            Inquinamento dell'aria e salute umana
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://www.arpa.piemonte.it/approfondimenti/temi-ambientali/ambiente-e-salute/dipartimento-tematico/attivita-1/introduzione"
            target="_blank"
          >
            Atlante Ambiente e Salute
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://www.arpa.piemonte.it/approfondimenti/temi-ambientali/ambiente-e-salute/dipartimento-tematico/attivita-1/attivita"
            target="_blank"
          >
            ARPA - Epidemiologia
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="http://www.medhiss.eu/" target="_blank">
            Progetto LIFE MEDHISS
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'ricerca-dati'">
      <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
      <v-list class="pt-0">
        <v-list-item>
          <!--a href="/valutare-aria/monitoraggio"> Rete di monitoraggio </a-->
          <router-link :to="{ name: 'monitoraggio' }">
            Rete di monitoraggio
          </router-link>
        </v-list-item>
      </v-list>
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="http://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/aria/pagina-aria-qualita-dellaria"
            target="_blank"
          >
            ARPA - Qualità dell'aria
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://www.eea.europa.eu/data-and-maps/explore-interactive-maps/up-to-date-air-quality-data"
            target="_blank"
          >
            EEA - Air Quality Index
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://discomap.eea.europa.eu/map/fme/AirQualityExport.htm"
            target="_blank"
          >
            EEA - Air Quality Data
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'fonti-emissive'">
      <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
      <v-list class="pt-0">
        <v-list-item>
          <!--a href="/valutare-aria/emissioni"> Inventario emissioni </a-->
          <router-link :to="{ name: 'emissioni' }">
            Inventario emissioni
          </router-link>
        </v-list-item>
      </v-list>
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="https://www.arpa.piemonte.it/news/inquinamento-da-particolato-pm10-le-fonti"
            target="_blank"
          >
            ARPA - PM<small>10</small>: le sorgenti
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="http://www.arpa.piemonte.it/news/inquinamento-da-particolato-pm10-il-riscaldamento-domestico"
            target="_blank"
          >
            ARPA - PM<small>10</small>: il riscaldamento domestico
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://www.arpa.piemonte.it/news/inquinamento-da-particolato-pm10-il-trasporto-su-strada"
            target="_blank"
          >
            ARPA - PM<small>10</small>: il trasporto su strada
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  props: ["page"],
  data() {
    return {
      scrolled: false
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>
