<template>
  <v-container fluid>
    <Header headerClass="qualita-aria" title="Qualità dell'aria" />
    <v-container page class="fonti-emissive">
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Fonti Emissive</h2>
          <p class="mt-40">
            Seleziona l’<strong>inquinante</strong> per vedere le percentuali
            riferite alle diverse fonti emissive.
          </p>
          <v-tabs
            :color="tabColori"
            v-model="tabFonti"
            icons-and-text
            grow
            next-icon="fas fa-chevron-right"
            prev-icon="fas fa-chevron-left"
            show-arrows
            class="mt-40 tab-custom"
          >
            <v-tab active>
              <span>PM</span>
              <v-icon x-large>$vuetify.icons.pmIcon</v-icon>
            </v-tab>

            <v-tab>
              <span>SO<small>2</small></span>
              <v-icon x-large>$vuetify.icons.soIcon</v-icon>
            </v-tab>

            <v-tab>
              <span>NO<small>x</small></span>
              <v-icon x-large>$vuetify.icons.noIcon</v-icon>
            </v-tab>

            <v-tab>
              <span>NH<small>3</small></span>
              <v-icon x-large>$vuetify.icons.nhIcon</v-icon>
            </v-tab>

            <v-tab>
              <span>COV</span>
              <v-icon x-large>$vuetify.icons.covIcon</v-icon>
            </v-tab>

            <v-tab>
              <span>CH<small>4</small></span>
              <v-icon x-large>$vuetify.icons.ch4Icon</v-icon>
            </v-tab>

            <v-tab>
              <span>CO</span>
              <v-icon x-large>$vuetify.icons.coIcon</v-icon>
            </v-tab>

            <v-tab>
              <span>CO<small>2</small></span>
              <v-icon x-large>$vuetify.icons.co2Icon</v-icon>
            </v-tab>

            <v-tab>
              <span>N<small>2</small>O</span>
              <v-icon x-large>$vuetify.icons.no2Icon</v-icon>
            </v-tab>

            <v-tab-item class="bk-pm">
              <p class="mt-5">
                Una miscela di particelle solide e liquide sospese nell’aria.
                PM₁₀ si riferisce a particelle con un diametro inferiore a 10
                micrometri. PM₂.₅ si riferisce a particelle fini con diametri di
                2,5 micrometri e inferiori. Entrambi i tipi possono essere
                facilmente inalati.
              </p>
              <FontiChart
                v-bind:chartData="chartDataPM"
                v-bind:chartColor="'#07B6D7'"
              >
              </FontiChart>
            </v-tab-item>

            <v-tab-item class="bk-so">
              <p class="mt-5">
                L'anidride solforosa è un gas pungente e incolore emesso da
                centrali elettriche, industrie, spedizioni e abitazioni. Una
                delle principali cause delle piogge acide.
              </p>
              <FontiChart
                v-bind:chartData="chartDataSO"
                v-bind:chartColor="'#e4cc0c'"
              >
              </FontiChart>
            </v-tab-item>

            <v-tab-item class="bk-nox">
              <p class="mt-5">
                Si riferisce ad una famiglia di gas che include ossido di azoto
                (NO) e biossido di azoto (NO<small>2</small>). La foschia
                marrone a volte vista sulle città è principalmente ossidi di
                azoto. Vengono emessi da veicoli, spedizioni, centrali
                elettriche, industrie e abitazioni.
              </p>
              <FontiChart
                v-bind:chartData="chartDataNO"
                v-bind:chartColor="'#3cac8c'"
              >
              </FontiChart>
            </v-tab-item>

            <v-tab-item class="bk-nh3">
              <p class="mt-5">
                L'ammoniaca è un gas incolore, caustico e irritante con odore
                pungente. Viene emesso dai reflui zootecnici da allevamento e
                dall'uso di fertilizzanti in agricoltura.
              </p>
              <FontiChart
                v-bind:chartData="chartDataNH"
                v-bind:chartColor="'#c44cbc'"
              >
              </FontiChart>
            </v-tab-item>
            <v-tab-item class="bk-cov">
              <p class="mt-5">
                I Composti Organici Volatili sono una classe di composti
                chimici. In condizioni normali sono gassosi o possono
                vaporizzare ed entrare nell'atmosfera. Eccetto il metano, sono
                emessi dall'uso di solventi, veicoli stradali, riscaldamento
                domestico e produzione di energia.
              </p>
              <FontiChart
                v-bind:chartData="chartDataCOV"
                v-bind:chartColor="'#ec0464'"
              >
              </FontiChart>
            </v-tab-item>
            <v-tab-item class="bk-ch4">
              <p class="mt-5">
                Il metano è un inquinante atmosferico e un gas serra. Le fonti
                più importanti sono il settore energetico, le discariche,
                l'agricoltura e l’allevamento.
              </p>
              <FontiChart
                v-bind:chartData="chartDataCH4"
                v-bind:chartColor="'#044c7c'"
              >
              </FontiChart>
            </v-tab-item>
            <v-tab-item class="bk-co">
              <p class="mt-5">
                Il monossido di carbonio è un gas incolore, inodore e insapore,
                leggermente meno denso dell'aria. In concentrazioni elevate è
                altamente tossico per l’uomo e gli animali. Viene generato da
                qualsiasi combustione.
              </p>
              <FontiChart
                v-bind:chartData="chartDataCO"
                v-bind:chartColor="'#e4a434'"
              >
              </FontiChart>
            </v-tab-item>
            <v-tab-item class="bk-co2">
              <p class="mt-5">
                L’anidride carbonica è un ossido acido di fondamentale
                importanza nei processi vitali di piante e animali. È il
                risultato della combustione di un composto organico in presenza
                di una quantità di ossigeno sufficiente a completarne
                l'ossidazione. In natura viene anche prodotta da batteri
                aerobici durante il processo della fermentazione alcolica ed è
                il sottoprodotto della respirazione.
              </p>
              <FontiChart
                v-bind:chartData="chartDataCO2"
                v-bind:chartColor="'#6cbcc4'"
              >
              </FontiChart>
            </v-tab-item>
            <v-tab-item class="bk-n2o">
              <p class="mt-5">
                Il protossido di azoto è un gas incolore, non infiammabile,
                dall'odore lievemente dolce. Noto come gas esilarante, trova
                impiego medico come analgesico e anestetico ed è anche un
                importante gas serra e inquinante atmosferico. La più comune via
                di sintesi consiste nel cauto riscaldamento del nitrato di
                ammonio, che si decompone in protossido di azoto e vapore
                acqueo.
              </p>
              <FontiChart
                v-bind:chartData="chartDataN20"
                v-bind:chartColor="'#5c3cac'"
              >
              </FontiChart>
            </v-tab-item>
          </v-tabs>
          <p>
            Ripartizione per comparto emissivo - 2015
            <br />
            Fonte: IREA
          </p>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuQualitaAria page="fonti-emissive" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuQualitaAria from "@/views/qualita-aria/MenuQualitaAria";
import FontiChart from "@/views/qualita-aria/FontiChart";

export default {
  components: {
    Header,
    MenuQualitaAria,
    FontiChart
  },
  data() {
    return {
      tabFonti: 0,
      chartDataPM: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "0,15"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "45,31"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "3,31"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "1,4"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "0"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "2,27"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "32,08"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "2,54"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "0,01"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "5,2"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "7,73"
        }
      ],
      chartDataSO: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "0,67"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "8,23"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "46,92"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "39,93"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "0"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "0"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "0,63"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "0,56"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "0,26"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "1,24"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "1,58"
        }
      ],
      chartDataNO: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "4,48"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "9,60"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "19,40"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "3,44"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "0"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "0,24"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "50,66"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "9,77"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "0,43"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "1.09"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "0,90"
        }
      ],
      chartDataNH: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "0,02"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "0,47"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "0,06"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "0,09"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "0"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "0,03"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "0,85"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "0"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "2,72"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "95,39"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "0,37"
        }
      ],
      chartDataCOV: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "0,16"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "4,46"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "1,43"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "9,13"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "1,09"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "10,37"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "8,66"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "0,62"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "0,02"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "21,95"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "42,12"
        }
      ],
      chartDataCH4: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "0,51"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "4,55"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "0,19"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "0,31"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "2,62"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "0"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "0,66"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "0,01"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "15,88"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "73,79"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "1,58"
        }
      ],
      chartDataCO: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "1,29"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "44,01"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "2,64"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "0,35"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "0"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "0,32"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "36,36"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "1,77"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "0,05"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "2,96"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "10,24"
        }
      ],
      chartDataCO2: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "16,65"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "21,39"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "31,96"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "1,09"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "0"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "0"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "24,66"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "2,11"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "2,13"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "0"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "0"
        }
      ],
      chartDataN20: [
        {
          title: "Produzione energia e trasformazione combustibili",
          imgName: "factory-b.png",
          value: "0,44"
        },
        {
          title: "Combustione non industriale",
          imgName: "heater-b.png",
          value: "7,00"
        },
        {
          title: "Combustione nell’industria",
          imgName: "oil-tank-b.png",
          value: "3,16"
        },
        {
          title: "Processi produttivi",
          imgName: "production-b.png",
          value: "3,46"
        },
        {
          title: "Estrazione e distribuzione combustibili",
          imgName: "oil-pump-b.png",
          value: "0"
        },
        {
          title: "Uso di solventi",
          imgName: "gas-mask-b.png",
          value: "0"
        },
        {
          title: "Trasporto su strada",
          imgName: "delivery-truck-b.png",
          value: "5,14"
        },
        {
          title: "Altre sorgenti mobili e macchinari",
          imgName: "ship-b.png",
          value: "0,75"
        },
        {
          title: "Trattamento e smaltimento rifiuti",
          imgName: "waste-water-b.png",
          value: "1,22"
        },
        {
          title: "Agricoltura",
          imgName: "tractor-b.png",
          value: "78,80"
        },
        {
          title: "Altre sorgenti",
          imgName: "wind-energy-b.png",
          value: "0,01"
        }
      ]
    };
  },
  computed: {
    tabColori: function() {
      let colore = "";
      switch (this.tabFonti) {
        case 0:
          colore = "#07b6d7";
          break;
        case 1:
          colore = "#E4CC0C";
          break;
        case 2:
          colore = "#39AE8B";
          break;
        case 3:
          colore = "#C049BC";
          break;
        case 4:
          colore = "#ec0464";
          break;
        case 5:
          colore = "#044c7c";
          break;
        case 6:
          colore = "#e4a434";
          break;
        case 7:
          colore = "#6cbcc4";
          break;
        case 8:
          colore = "#5c3cac";
          break;
        default:
          break;
      }
      return colore;
    }
  }
};
</script>
