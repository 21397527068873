<template>
  <v-container>
    <div v-for="(item, i) in chartData" :key="i" class="border">
      <p class="mt-40">
        <img
          :src="require('../../assets/stylesheets/im/' + item.imgName)"
          aria-hidden="true"
          alt=""
        />
        &nbsp;<strong>{{ item.title }}</strong>
      </p>
      <v-progress-linear :value="item.value" v-bind:color="chartColor">
        <strong>{{ item.value }} %</strong>
      </v-progress-linear>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "FontiChart",
  props: ["chartData", "chartColor"]
};
</script>
